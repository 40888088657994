<template>
  <section class="section section-standalone" id="pendapatan-daerah">
    <div class="container-fluid">
      <div class="my-3">
        <div class="c-dashboard-title d-flex justify-content-between pl-2">
          <h1 class="primary-font text-size-title text-uppercase mb-0">
            Pendapatan Daerah
          </h1>
          <div class="c-dashboard-title-option d-flex align-items-center">
            <select
              class="form-control form-control-sm mr-2 form-control-custom"
              v-model="bulan"
              v-on:change="bulanChange()"
              id="bulan"
            >
              <option
                :value="++key"
                v-for="(item, key) in listBulan"
                :key="key"
                >{{ item }}</option
              >
            </select>
            <select
              class="form-control form-control-sm mr-2 form-control-custom"
              v-model="tahun"
              v-on:change="tahunChange()"
            >
              <option :value="item" v-for="item in array_tahun" :key="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        <div class="row py-4 align-items-center justify-content-center">
          <div class="col-md-12">
            <div class="bg-white rounded border border-radius-style">
              <div class="row p-3">
                <div class="col-md-6">
                  <div class="chart-doughnut--wrapper mt-5 mb-4">
                    <canvas
                      id="pendapatanDerah"
                      width="400"
                      height="200"
                      :chart="chart"
                    ></canvas>
                    <div class="chart-doughnut--text">
                      <h2
                        class="mb-0 secondary-font text-size-large font-weight-bold"
                      >
                        {{ parseFloat(progress).toFixed(2) }}%
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <p class="text-right">Akumulasi dari {{ labelTextShow }}</p>
                  <div
                    class="card-dashboard bg-yellow-new border-radius-style rounded-lg p-3 mb-3"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div class="flex-row c-list-item--gray">
                        <img
                          src="@/assets/img/icon/icon-rate.svg"
                          alt
                          width="50"
                        />
                      </div>
                      <div class="flex-row text-right">
                        <h4
                          class="text-warning d-flex align-items-center justify-content-end"
                        >
                          Rp.
                          {{ formatPrice(target) }}
                        </h4>
                        <p
                          class="mb-0 text-size-small text-uppercase font-weight-normal"
                        >
                          Target Pendapatan
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card-dashboard bg-blue-new border-radius-style rounded-lg p-3 mb-3"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div class="flex-row c-list-item--gray">
                        <img
                          src="@/assets/img/icon/icon-rate-blue.svg"
                          alt
                          width="50"
                        />
                      </div>
                      <div class="flex-row text-right">
                        <h4
                          class="text-info d-flex align-items-center justify-content-end"
                        >
                          Rp. {{ formatPrice(realisasi) }}
                        </h4>
                        <p
                          class="mb-0 text-size-small text-uppercase font-weight-normal"
                        >
                          Pendapatan yang sudah diterima
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card-dashboard bg-gray-new border-radius-style rounded-lg p-3"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div class="flex-row c-list-item--gray">
                        <img
                          src="@/assets/img/icon/icon-rate-gray.svg"
                          alt
                          width="50"
                        />
                      </div>
                      <div class="flex-row text-right">
                        <h4
                          class="text-light-gray d-flex align-items-center justify-content-end"
                        >
                          Rp. {{ formatPrice(deviasi) }}
                        </h4>
                        <p
                          class="mb-0 text-size-small text-uppercase font-weight-normal"
                        >
                          Deviasi
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div
              class="card-dashboard bg-white rounded border border-radius-style"
            >
              <div class="p-3">
                <h4
                  class="mb-0 text-size-large titilium-font font-weight-normal"
                >
                  Pendapatan Daerah
                </h4>
              </div>
              <div class="card-dashboard-content scroll-md py-3 pl-3 pr-4">
                <div
                  class="d-flex align-items-center mb-2"
                  v-for="(item, key) in pendapatan"
                  :key="key"
                >
                  <div class="flex-row mr-3">
                    <span
                      class="primary-font c-list-number text-body text-size-large"
                      >{{ ++key }}</span
                    >
                  </div>
                  <div class="flex-row w-100">
                    <div
                      class="progress-lable d-flex align-items-center justify-content-between mb-1"
                    >
                      <p
                        class="text-size-normal mb-0 text-capitalize text-body"
                      >
                        {{ item.jenis_penerimaan }}
                      </p>
                      <div class="d-flex align-items-center">
                        <p
                          class="text-size-small mb-0 mr-2 text-body secondary-font"
                        >
                          Rp. {{ formatPrice(item.realisasi) }}
                        </p>
                        <span
                          class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                          >{{ parseFloat(item.persentase).toFixed(2) }}%</span
                        >
                      </div>
                    </div>
                    <div class="progress" style="height: 6px;">
                      <div
                        class="progress-bar bg-success rounded-right"
                        role="progressbar"
                        :style="'width:' + parseFloat(item.persentase) + '%'"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center my-3">
                <button class="btn btn-primary" @click="showPendapatan()">
                  Tampilkan {{ labelButtonShow }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row sumber-data mt-3"
        v-if="sumber_data != null"
        v-tooltip.top-start="{
          content: `Pic : ${
            sumber_data.pic == null ? '-' : sumber_data.pic
          } <br>
                              Kontak : ${
                                sumber_data.kontak == null
                                  ? '-'
                                  : sumber_data.kontak
                              }`,
          show: sumberTooltip,
          trigger: 'manual'
        }"
        style="cursor:pointer"
        v-on:mouseover="changeSumberTooltip"
        v-on:click="changeSumberTooltip"
      >
        <div class="col-md-12 ">
          <p class="text-size-normal ">
            &nbsp; Sumber Data : {{ sumber_data.sumber }} <br />
            &nbsp; Pembaharuan Terakhir :
            {{ sumber_data.updated_at == null ? '-' : sumber_data.updated_at }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
let date = new Date(window.date_now);
export default {
  metaInfo() {
    return {
      title: 'Pendapatan'
    };
  },
  data() {
    return {
      sumberTooltip: false,
      sumber_data: null,
      chart: {
        datasets: [
          {
            data: [60, 10],
            backgroundColor: ['#01a7e5', '#cbcbcb']
          }
        ],
        type: 'doughnut',
        options: {
          legend: {
            display: false
          },
          cutoutPercentage: 60,
          responsive: true,
          maintainAspectRatio: false
        }
      },
      target: 0,
      realisasi: 0,
      deviasi: 0,
      progress: 0,
      listBulan: window.date_month,
      tahun: date.getFullYear(),
      bulan: date.getMonth() != 0 ? date.getMonth() + 1 : 1,
      rawPendapatan: [],
      pendapatan: [],
      array_tahun: window.date_years,
      defaultPajak: [
        'Pajak Kendaraan Bermotor (PKB)',
        'Bea Balik Nama Kendaraan Bermotor I (BBNKB I)',
        'Bea Balik Nama Kendaraan Bermotor II (BBNKB II)',
        'Pajak Bahan Bakar Kendaraan Bermotor (PBBKB)',
        'Pajak Air Permukaan (PAP)'
      ],
      showDefault: true
    };
  },
  mounted() {
    window.axios
      .post(window.base_api + 'page/pendapatan', {
        tahun: this.tahun,
        bulan: this.bulan
      })
      .then(response => {
        if (response.status == 200) {
          this.sumber_data = response.data.sumber_data;
        }
      });
    this.LoadChart();
  },
  computed: {
    labelButtonShow() {
      return this.showDefault
        ? 'seluruh pendapatan'
        : '5 data pendapatan pilihan';
    },
    labelTextShow() {
      return this.showDefault
        ? '5 data pendapatan pilihan'
        : 'seluruh pendapatan';
    }
  },
  methods: {
    changeSumberTooltip() {
      this.sumberTooltip = true;
      setTimeout(() => {
        this.sumberTooltip = false;
      }, 3000);
    },
    LoadChart() {
      this.$store.commit('changeLoading', true);
      window.axios
        .get(
          `${window.links.pendapatan_daerah}/api/v1/potensipajak_rinci/health?api_key=${process.env.VUE_APP_PENDAPATAN_DAERAH_API_KEY}`
        )
        .then(() => {
          window.axios
            .get(
              `${window.links.pendapatan_daerah}/api/v1/potensipajak_rinci/potensipajak_rinci?api_key=${process.env.VUE_APP_PENDAPATAN_DAERAH_API_KEY}&tahun=${this.tahun}&bulan=${this.bulan}`
            )
            .then(response => {
              this.rawPendapatan = response.data.data.content;
              if (this.rawPendapatan.length > 0) {
                this.rawPendapatan = this.rawPendapatan.map(item => {
                  if (parseInt(item.target) !== 0) {
                    item.persentase = (
                      (parseInt(item.realisasi) / parseInt(item.target)) *
                      100
                    ).toFixed(2);
                  } else {
                    item.persentase = 0;
                  }
                  return item;
                });
                this.rawPendapatan = this.rawPendapatan.sort(this.compare);
              }
              this.showPendapatan(false);
              this.$store.commit('changeLoading', false);
            });
        })
        .catch(() => {
          this.$store.commit('changeLoading', false);
          alert('API mati');
        });
    },
    setChart() {
      var ctx = document.getElementById('pendapatanDerah').getContext('2d');
      let chartData = {
        datasets: this.chart.datasets
      };

      new window.Chart(ctx, {
        type: this.chart.type,
        data: chartData,
        options: this.chart.options
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    sumArray(array) {
      return array.reduce((a, b) => a + b, 0);
    },
    getMonth() {
      const monthNames = window.date_month;

      return monthNames[date.getMonth()];
    },
    bulanChange() {
      this.LoadChart();
    },
    tahunChange() {
      this.LoadChart();
    },
    compare(a, b) {
      a = parseFloat(a.persentase);
      b = parseFloat(b.persentase);

      let comparison = 0;
      if (a > b) {
        comparison = 1;
      } else if (a < b) {
        comparison = -1;
      }
      return comparison * -1;
    },
    showPendapatan(autoSwitch = true) {
      if (autoSwitch) this.showDefault = !this.showDefault;
      this.pendapatan = this.rawPendapatan;

      if (this.showDefault)
        this.pendapatan = this.rawPendapatan.filter(item =>
          this.defaultPajak.includes(item.jenis_penerimaan)
        );

      const target = this.pendapatan.map(item => {
        return parseFloat(item.target);
      });

      const realisasi = this.pendapatan.map(item => {
        return parseFloat(item.realisasi);
      });

      this.target = this.sumArray(target);
      this.realisasi = this.sumArray(realisasi);
      this.deviasi = this.target - this.realisasi;
      this.chart.datasets[0].data = [
        parseInt((this.realisasi / this.target) * 100),
        parseInt((this.deviasi / this.target) * 100)
      ];
      this.progress = (this.realisasi / this.target) * 100 || 0;
      this.setChart();
    }
  }
};
</script>

<style>
#bulan {
  width: 120px !important;
}
</style>
